<template>
  <div class="min-h-screen">
    <section class="layout bg-blue-darker pt-32 pb-44">
      <div class="container pt-20 pb-10">
        <h1 class="text-4xl font-medium leading-normal text-white text-center">Lorem ipsum dolor</h1>
      </div>
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <skewed-separator class="text-white" />
    </section>
    <div class="container overlap">
      <div class="flex justify-center">
        <card class="w-full md:w-1/2 p-4">
          <notice v-if="errors != false" type="error" :messages="errors" />
          <form v-if="show">
            <input-container icon="tag">
              <input type="text" v-model="form.name" placeholder="Name" />
            </input-container>
            <input-container icon="letter">
              <input type="email" v-model="form.email" placeholder="Email" required />
            </input-container>
            <input-container icon="key">
              <input type="password" v-model="form.password" placeholder="Password" required />
            </input-container>
            <btn class="btn-orange w-full" @click.native="submit">Register</btn>
          </form>
          <div v-else>Please verify your email address</div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import SkewedSeparator from '../partials/SkewedSeparator';
import Card from '../partials/Card';
import Icon from '../partials/Icon';
import Btn from '../partials/Btn';
import CircleBg from '../partials/CircleBg';
import InputContainer from '../partials/InputContainer';
import Notice from '../partials/Notice';
export default {
  components: { Notice, InputContainer, CircleBg, Btn, Icon, Card, SkewedSeparator },
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
      },
      show: true,
      errors: false
    };
  },
  methods: {
    submit() {
      this.errors = false;

      this.$store.dispatch(window.store.STORE_NEW_USER, this.form)
        .then((response) => {
          this.show = false;
        }).catch((errors) => {
          this.errors = errors;
        });
    },
    // reset() {
    //   // Reset our form values
    //   this.form.name = '';
    //   this.form.email = '';
    //   this.form.password = '';
    //   // Trick to reset/clear native browser form validation state
    //   this.show = false;
    //   this.$nextTick(() => {
    //     this.show = true;
    //   });
    // },
  },
};
</script>
